import TodayStatCard from "../components/TodayStatCard";
import OverallStatCard from "../components/OverallStatCard";
import TVMCard from "../components/TVMCard";
import GenericStatCard from "../components/GenericStatCard";

const LeaderBoard = () => {
    return (
        <div className="container">
            <div className="mb-10">
                <TodayStatCard />
            </div>
            <div className="mb-10">
                <TVMCard />
            </div>
            <div className="mb-10">
                <OverallStatCard />
            </div>
            <div className="mb-10">
                <GenericStatCard />
            </div>
        </div>
    );
};

export default LeaderBoard;
