import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import utils from '../util';

const TopGainerTable = (props) => {
    const { dataList, headerList } = props;

    return (
        <div className="flex flex-col items-center">
            <span className="text-[32px]">{props.title}</span>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 400, maxHeight: 400 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {
                                headerList?.length > 0 && headerList.map((header, index) => (
                                    <TableCell key={index}>{header.title}</TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataList && dataList.length > 0 ? dataList.map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {headerList && utils.formatString(row[headerList[0].key])}
                                </TableCell>
                                <TableCell align="right">
                                    {headerList && row[headerList[1].key].toFixed(2)}
                                </TableCell>
                            </TableRow>
                        )) : (
                            <div className='w-full flex justify-center'>
                                <span className="text-[25px]">No Data</span>
                            </div>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default TopGainerTable;