import { Link, useLocation } from "react-router-dom";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";

const Header = ({ setConnected }) => {
  const wallet = useWallet();
  const { pathname } = useLocation();
  return (
    <div className="z-20 flex flex-row justify-between items-center px-[50px] pt-3">
      <div className="w-full md:h-[60px] sm:h-[120px] flex flex-row justify-start items-center px-[50px]">
        <img alt="" src="favicon.jpeg" className="w-[40px] h-[40px] rounded-[20px] mr-3" />
        <span className="text-[32px] text-black italic font-extrabold mr-[50px]">Turt Flip</span>
        <Link to="/">
          <span className={`text-xl mr-3 ${pathname === '/' && 'text-blue-600'}`}>Home</span>
        </Link>
        <Link to="/leaderboard">
          <span className={`text-xl ${pathname === '/leaderboard' && 'text-blue-600'}`}>Leaderboard</span>
        </Link>
      </div>
      {
        wallet.connected &&
        <WalletMultiButton className="wallet-adapter-button-trigger"></WalletMultiButton>
      }
    </div >
  );
};

export default Header;
