import { useEffect, useState } from 'react';
import { getStatInfo } from '../actions';

const GenericStatCard = () => {
    const [statInfo, setStatInfo] = useState(null);

    useEffect(() => {
        getStat();
    }, []);

    const getStat = () => {
        getStatInfo({ today: true })
            .then(result => { setStatInfo(result); })
            .catch(err => {
                setStatInfo(null);
            })
    };

    return (
        <div className="w-full p-3 bg-white bg-opacity-50 shadow-2xl shadow-gray-600">
            <div className="flex flex-row items-center justify-center col-span-2">
                <div className='grid grid-cols-2 gap-12'>
                    <div className='flex flex-col items-center'>
                        <span className="text-[25px]">
                            Highest Winning Streak
                        </span>
                        <span className="text-[30px] font-bold">
                            {statInfo ? statInfo.maxWinCount : '-'}
                        </span>
                    </div>
                    <div className='flex flex-col items-center'>
                        <span className="text-[25px]">
                            Highest Losing Streak
                        </span>
                        <span className="text-[30px] font-bold">
                            {statInfo ? statInfo.maxLossCount : '-'}
                        </span>
                    </div>
                    <div className='flex flex-col items-center'>
                        <span className="text-[25px]">
                            Total Flipped
                        </span>
                        <span className="text-[30px] font-bold">
                            {statInfo && statInfo.totalVolume ? statInfo.totalVolume.toFixed(2) : '-'} SOL
                        </span>
                    </div>
                    <div className='flex flex-col items-center'>
                        <span className="text-[25px]">
                            Total Matches
                        </span>
                        <span className="text-[30px] font-bold">
                            {statInfo ? statInfo.totalMatches : '-'}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GenericStatCard;