import { useEffect, useState } from 'react';
import TopGainerTable from "./TopGainerTable";
import { getVolumeManager, getStatInfo } from '../actions';

const TVMCard = () => {
    const [volumeData, setVolumeData] = useState([]);
    const [statInfo, setStatInfo] = useState(null);

    useEffect(() => {
        getVolume();
        getStat();
    }, []);

    const getVolume = () => {
        getVolumeManager({ today: true })
            .then(result => { setVolumeData(result); })
            .catch(err => {
                setVolumeData([]);
            })
    };

    const getStat = () => {
        getStatInfo({ today: true })
            .then(result => { setStatInfo(result); })
            .catch(err => {
                setStatInfo(null);
            })
    };

    return (
        <div className="w-full p-3 bg-white bg-opacity-50 shadow-2xl shadow-gray-600">
            <div className="grid grid-cols-5 gap-3">
                <div className="col-span-2">
                    <TopGainerTable
                        title="Total Volume Manager (24 hrs)"
                        dataList={volumeData}
                        headerList={[
                            { title: 'Wallet ID', key: 'address' },
                            { title: 'Volume', key: 'volume' }
                        ]}
                    />
                </div>
                <div className="flex flex-row items-center justify-center col-span-3">
                    <div className='grid grid-cols-2 gap-24'>
                        <div className='flex flex-col items-center'>
                            <span className="text-[25px]">
                                Today's Highest Winning
                            </span>
                            <span className="text-[25px]">
                                Streak
                            </span>
                            <span className="text-[30px] font-bold">
                                {statInfo ? statInfo.maxWinCount : '-'}
                            </span>
                        </div>
                        <div className='flex flex-col items-center'>
                            <span className="text-[25px]">
                                Today's Highest Losing
                            </span>
                            <span className="text-[25px]">
                                Streak
                            </span>
                            <span className="text-[30px] font-bold">
                                {statInfo ? statInfo.maxLossCount : '-'}
                            </span>
                        </div>
                        <div className='flex flex-col items-center'>
                            <span className="text-[25px]">
                                Total Flipped
                            </span>
                            <span className="text-[30px] font-bold">
                                {statInfo && statInfo.totalVolume ? statInfo.totalVolume.toFixed(2) : '-'} SOL
                            </span>
                        </div>
                        <div className='flex flex-col items-center'>
                            <span className="text-[25px]">
                                Total Matches
                            </span>
                            <span className="text-[30px] font-bold">
                                {statInfo ? statInfo.totalMatches : '-'}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TVMCard;