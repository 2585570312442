import { useMemo, useEffect, useState } from "react";
// import { Wallets } from './components/wallet'
import { SnackbarProvider } from 'notistack';
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  GlowWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  // SolletExtensionWalletAdapter,
  // SolletWalletAdapter,
  TorusWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import {
  WalletModalProvider,
  // WalletDisconnectButton,
  // WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
// import { clusterApiUrl } from "@solana/web3.js";
import "@solana/wallet-adapter-react-ui/styles.css";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
// import Home from "./pages/Home";
import Layout from "./components/Layout";
import "./App.css";

function App() {
  // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
  const network = WalletAdapterNetwork.Devnet;

  // You can also provide a custom RPC endpoint.
  // const endpoint = useMemo(() => clusterApiUrl(network), [network]);
  const endpoint = "https://devnet.helius-rpc.com/?api-key=90140e55-1db8-4915-9183-528941ba617d";
  // const endpoint = "http://127.0.0.1:8899";
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new GlowWalletAdapter(),
      new SlopeWalletAdapter(),
      // new SolflareWalletAdapter({ network }),
      new SolflareWalletAdapter({ endpoint }),
      new TorusWalletAdapter(),
    ],
    [network]
  );
  const wallet = useWallet();
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    if (wallet && wallet.connected) {
      const handleAccountChange = async () => {
        try {
          await wallet.disconnect();
          await wallet.connect();
        }
        catch (err) {
          console.log('Failed to reconnect', err);
        }
      };
      wallet.on('accountChanged', handleAccountChange);

      // Cleanup event listener on unmount
      return () => {
        wallet.off('accountChanged', handleAccountChange);
      };
    }
  }, [wallet]);

  return (
    <div className="">
      <SnackbarProvider>
        <ConnectionProvider endpoint={endpoint}>
          <WalletProvider wallets={wallets} autoConnect>
            <WalletModalProvider>
              <Layout setConnected={setConnected} />
            </WalletModalProvider>
          </WalletProvider>
        </ConnectionProvider>
      </SnackbarProvider>
    </div>
  );
}

export default App;
