import { useEffect, useState } from "react";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { HourglassBottomTwoTone, ThumbUpOffAltTwoTone, ReplayTwoTone, SettingsTwoTone, InfoTwoTone, DeblurTwoTone, Check, SendTwoTone, CallReceivedTwoTone } from "@mui/icons-material";
import { coinFlip, initialize, getGlobalData, setGlobalData, getUserPendingRewards, claimRewards, isInitialized, getProgramBalance, deposit, withdraw } from "../contract/helpers";
import { showToast } from "../contract/utils";
import Dialog from '@mui/material/Dialog';
import { DialogContent, DialogActions, DialogTitle, Button, TextField, RadioGroup, Radio, FormControlLabel } from "@mui/material";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
// import { set } from "@project-serum/anchor/dist/cjs/utils/features";

const COIN_HEADS_STATE = 0;
const COIN_TAILS_STATE = 1;
const COIN_STOP_STATE = -1;

const AfterConnected = () => {

  const { connection } = useConnection();
  const wallet = useWallet();

  const [currentCoinOption, setCurrentCoinOption] = useState(0);
  const [currentSolOption, setCurrentSolOption] = useState(0);
  const [result, setResult] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [flip, setFlip] = useState(false);
  const [loading, setLoading] = useState(false);
  const coinOptions = ["Heads", "Tails"];
  const solOptions = ["0.05", "0.1", "0.25", "0.5", "1", "2"];

  const [open, setOpen] = useState(false);
  const [winPercentage, setWinPercentage] = useState(0);
  const [devWallet, setDevWallet] = useState("");
  const [devFee, setDevFee] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false);
  const [initialized, setInitialized] = useState(true);
  const [pendingRewards, setPendingRewards] = useState(0);
  const [globalInfo, setGlobalInfo] = useState(null);

  const [coinStatus, setCoinStatus] = useState(COIN_STOP_STATE);
  const [rerenderKey, setRerenderKey] = useState(0); //rerenderkey of coin image: when it will changed to 0 or 1, coin will rerender.

  const [actionFlag, setActionFlag] = useState(
    'deposit'
  );
  const [depositAmount, setDepositAmount] = useState(0);
  const [transferAmount, setTransferAmount] = useState('');

  useEffect(() => {
    updateSettingInfo();
  }, [wallet.publicKey]);

  useEffect(() => {
    if (isAdmin) {
      getBalance();
    }
  }, [isAdmin]);

  const getBalance = async () => {
    let balance = await getProgramBalance(wallet, connection);
    setDepositAmount(balance);
  };

  const closeModal = () => {
    setOpen(false);
    if (globalInfo) {
      setWinPercentage(globalInfo && globalInfo.winPercentage);
      setDevWallet(globalInfo && globalInfo.devWallet);
      setDevFee(globalInfo && globalInfo.devFee);
    }
  }

  const updateSettingInfo = async () => {
    try {
      let sData = await getGlobalData(wallet, connection);
      if (sData !== null) {
        setInitialized(true);
        setGlobalInfo(sData);
        let dev_win_percentage = sData.winPercentage;
        setWinPercentage(dev_win_percentage);

        let dev_fee = sData.devFee.toNumber();
        setDevFee(dev_fee);

        let dev_wallet = sData.devWallet.toBase58();
        setDevWallet(dev_wallet);

        if (wallet.publicKey.equals(sData.admin)) {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
      }

      else setInitialized(false);

      let tmpRewards = await getUserPendingRewards(wallet, connection);
      setPendingRewards(Number(tmpRewards));
    } catch (error) {
    }
  }

  const onOkClick = async () => {
    await setGlobalData(wallet, connection, devWallet, devFee, winPercentage);
    setOpen(false);
    await updateSettingInfo();
  };

  const onClaimRewads = async () => {
    setLoading(true);
    await claimRewards(wallet, connection);
    await updateSettingInfo();
    setLoading(false);
  }

  const initContract = async () => {
    setLoading(true);
    await initialize(wallet, connection);
    setLoading(false);
    updateSettingInfo();
  }

  const flipCoin = async () => {
    if (await isInitialized(wallet, connection) === false) {
      showToast("Not initialized", 2000, 1);
      return;
    }
    setLoading(true);

    if (!wallet || !wallet.publicKey) {
      showToast("Not Connected", 2000, 1);
      setLoading(false);
      return;
    }

    let coinFlipRes = null;
    try {
      let amount = Number(solOptions[currentSolOption]);
      let res = true;
      if (res === false) {
      } else {
        coinFlipRes = await coinFlip(wallet, connection, amount);

        //set coin spin option
        if (!coinFlipRes || (coinFlipRes && (coinFlipRes.txHash === null || coinFlipRes.txHash === undefined))) {
          setCoinStatus(COIN_STOP_STATE);
          setLoading(false);
          return;
        }
        if ((currentCoinOption === 0 && coinFlipRes.flipResult) || (currentCoinOption === 1 && !coinFlipRes.flipResult))
          setCoinStatus(COIN_HEADS_STATE);
        else setCoinStatus(COIN_TAILS_STATE);
        setRerenderKey(Math.abs(rerenderKey - 1));
        setResult(coinFlipRes.flipResult === true ? 'Congratulation! You won.' : 'Unfortunately, you failed.');
        setFlip(true);
      }
    } catch (error) {
      setCoinStatus(COIN_STOP_STATE);
      setLoading(false);
    }
    setTimeout(() => {
      setShowResult(true);
      setLoading(false);
      updateSettingInfo();
    }, 5000);
  };

  const onClickConfirm = async () => {
    if (+transferAmount <= 0) {
      showToast('Please set amount.', 2000, 3);
      return;
    } else {
      setLoading(true);
      if (actionFlag === 'deposit') {
        await deposit(wallet, connection, +transferAmount);
        await getBalance();
        setTransferAmount('');
      }
      else {
        await withdraw(wallet, connection, +transferAmount);
        await getBalance();
        setTransferAmount('');
      }
      setLoading(false);
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="flex flex-row items-center justify-center mb-3">
        {!isAdmin && pendingRewards > 0 && (
          <div className="flex flex-col items-center p-3 rounded-2xl bg-gradient-to-r from-[#b193bd] from-10% via-sky-500 via-30% to-[#48d512] to-90% font-bold">
            <div>
              <span className="text-1xl">Your Pending Rewards: </span>
              <span className="text-3xl">
                {pendingRewards === 0 ? '-' : pendingRewards}
              </span>
              <span className="text-1xl">{pendingRewards === 0 ? '' : 'SOL'}</span>
            </div>
            <button
              className={` ${loading ? "bg-gray-400" : "bg-yellow-400 "
                } ${(pendingRewards <= 0 || loading) && 'cursor-not-allowed'} px-4 py-2 rounded-md mt-4 font-bold text-sm md:text-[18px] text-dark flex flex-row items-center hover:outline-double outline-1 hover:outline-2 hover:scale-110`}
              onClick={() => {
                onClaimRewads();
              }}
              disabled={pendingRewards <= 0}
            >
              <DeblurTwoTone />
              Claim Rewards
            </button>
          </div>
        )}
        <div className="flex flex-row items-center justify-end">
          {!initialized && (
            <Button
              variant="outlined"
              onClick={() => initContract()}
              className="text-gray-700 hover:text-green-800"
            >
              <InfoTwoTone />
              Initialize
            </Button>
          )}

        </div>
      </div>


      {
        !isAdmin && (
          <div className="flex flex-col items-center justify-center">
            <div className="flex flex-row justify-center mb-10">
              <img src='/turtleCoin.png' alt="" className="w-48" />
            </div>
            <h2 className="font-bold text-4xl text-black mt-4">
              {showResult && (coinStatus === COIN_HEADS_STATE ? 'HEADS' : 'TAILS')}
            </h2>
            <h2 className={`font-bold text-4xl mt-4 ${showResult ? 'text-[#ad370c]' : 'text-gray-600 animate-pulse duration-500'}`}>
              {showResult ? `${result}` : "Please flip coin..."}
            </h2>
            <div className="grid  grid-flow-col justify-center gap-[20px] mt-8">
              {coinOptions.map((value, key) => (
                <button
                  onClick={() => {
                    setCurrentCoinOption(key);
                    setCoinStatus(COIN_STOP_STATE);
                    setShowResult(false);
                  }}
                  disabled={loading}
                  key={key}
                  className={`${loading && 'cursor-not-allowed'} uppercase px-10 py-3 bg-yellow-500 font-bold text-sm text-white md:text-xl shadow-lg hover:translate-x-2 hover:scale-110 ${currentCoinOption === key ? " border-gray-800 border-[3px] shadow-gray-500" : 'shadow-xl shadow-yellow-900 text-white'
                    }`}
                >
                  {currentCoinOption === key && <Check />}
                  {value}
                </button>
              ))}
            </div>
            <h2 className={`font-bold text-4xl mt-4 text-gray-600`}>
              FOR
            </h2>
            <div className="grid grid-cols-3 gap-4 mt-4">
              {solOptions.map((value, key) => (
                <button
                  onClick={() => setCurrentSolOption(key)}
                  disabled={loading}
                  key={key}
                  className={`${loading && 'cursor-not-allowed'} uppercase py-2 px-4 border-[1px] font-extrabold text-sm md:text-xl shadow-lg hover:translate-x-2 hover:scale-110 ${currentSolOption === key ? "bg-white text-black shadow-black shadow-2" : "bg-yellow-500 text-black shadow-md shadow-yellow-900"
                    }`}
                >
                  {value}&nbsp;SOL
                </button>
              ))}
            </div>
            <div className="h-[1px] w-full bg-black mt-3"></div>
            <button
              className={`text-black flex flex-row justify-center items-center h-12 w-32 md:h-16 md:w-48 hover:translate-x-2 hover:scale-110 ${loading ? "bg-gray-400 shadow-md shadow-gray-900 pointer-events-none h-16" : "bg-yellow-500 shadow-md shadow-yellow-900"
                } px-4 py-2 rounded-md mt-4 font-bold text-sm md:text-xl text-dark`}
              onClick={() => {
                setFlip(false);
                setShowResult(false);
                flipCoin();
              }}
            >
              {
                loading ? <HourglassBottomTwoTone className="animate-spin" /> : showResult ? <ReplayTwoTone /> : <ThumbUpOffAltTwoTone />
              }
              {loading
                ? "Processing..."
                : showResult
                  ? "PLAY AGAIN"
                  : "Flip"}
            </button>
          </div>
        )
      }

      {/* Admin view port */}
      {
        isAdmin && (
          <>
            <div className="flex flex-row justify-center text-[32px]">
              <span>Hi, Admin. Welcome to login!</span>
            </div>
            <div className="flex flex-row items-center justify-between w-full mb-2">
              <Button
                className="flex flex-row items-center ml-5"
                onClick={() => setOpen(true)}
                variant="outlined"
              >
                <SettingsTwoTone className="text-gray-700 hover:text-green-800 hover:scale-110" /> Settings
              </Button>
            </div>
            <div className="p-2 outline-double outline-2">
              <div className="flex flex-row items-end">
                <span className="mr-3 text-1xl">Deposited Amount:</span>
                <span className="text-3xl">
                  {depositAmount === 0 ? '-' : depositAmount}
                </span>
                <span className="text-1xl">{depositAmount === 0 ? '' : 'SOL'}</span>
              </div>
              <div className="flex flex-row items-center">
                <RadioGroup row
                  value={actionFlag}
                  onChange={e => {
                    if (actionFlag === 'deposit') setActionFlag('withdraw');
                    else setActionFlag('deposit');
                  }}
                >
                  <FormControlLabel value="deposit" control={<Radio />} label="deposit" />
                  <FormControlLabel value="withdraw" control={<Radio />} label="withdraw" />
                </RadioGroup>
                <TextField
                  type="number"
                  value={transferAmount}
                  onChange={(e) => {
                    let newValue = e.target.value;
                    if (newValue === '') {
                      setTransferAmount('');
                    }
                    else {
                      newValue = newValue.replace(/^0+/, '');
                      setTransferAmount(e.target.value);
                    }
                  }}
                  placeholder="Please type amount."
                  inputProps={{ min: 0 }}
                />
                SOL
              </div>
              <div className="flex flex-row justify-end mt-3">
                <Button variant="contained" onClick={onClickConfirm}>
                  {
                    loading ? <HourglassBottomTwoTone className="animate-spin" /> : actionFlag === 'deposit' ? <SendTwoTone /> : <CallReceivedTwoTone />
                  }
                  {
                    actionFlag === 'deposit' ? 'Send' : 'Receive'
                  }
                </Button>
              </div>
            </div>
            <Dialog
              open={open}
              onClose={closeModal}
            >
              <DialogTitle className="flex flex-row items-center">
                <SettingsTwoTone />Admin Setting
              </DialogTitle>
              <DialogContent>
                <div className="grid grid-cols-2 mb-2 gas-4">
                  <div className="col-span-4">Winning Percentage</div>
                  <div className="col-span-10">
                    <TextField
                      type="number"
                      value={winPercentage}
                      onChange={(e) => {
                        if (0 <= +e.target.value && +e.target.value <= 100)
                          setWinPercentage(+e.target.value);
                        else showToast('Uncorect Winning Percentage', 2000, 1);
                      }}
                    />
                  </div>
                </div>
                {/* <div className="grid grid-cols-2 mb-2 gas-4">
                  <div className="col-span-1">Developer Address</div>
                  <div className="col-span-2">
                    <TextField
                      value={devWallet}
                      onChange={(e) => setDevWallet(e.target.value)}
                    />
                  </div>0
                </div>
                <div className="grid grid-cols-2 mb-2 gas-4">
                  <div className="col-span-1">Dev Fee</div>
                  <div className="col-span-2">
                    <TextField
                      type="number"
                      value={devFee}
                      onChange={(e) => setDevFee(+e.target.value)}
                    />
                  </div>
                </div> */}
              </DialogContent>
              <DialogActions>
                <Button variant="contained" onClick={closeModal}>Cancel</Button>
                <Button variant="contained" onClick={onOkClick}>
                  {loading && <HourglassBottomTwoTone className="text-white animate-spin" />}
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )
      }
    </div >
  );
};

export default AfterConnected;
