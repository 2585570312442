import React from "react";
import { Routes, Route } from "react-router-dom";
// import Footer from "./Footer";
import Header from "./Header";
import Home from "../pages/Home";
import LeaderBoard from "../pages/LeaderBoard";

const Layout = ({ setConnected }) => {
  return (
    <div className="flex flex-col justify-between min-h-screen layout">
      <Header setConnected={setConnected} />
      <div className="z-10 flex flex-col items-center justify-start flex-1">
        {/* {children} */}
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/leaderboard' element={<LeaderBoard />} />
        </Routes>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default Layout;
