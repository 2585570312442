
import { web3 } from '@project-serum/anchor';
import {
    // Keypair,
    PublicKey,
    // SYSVAR_CLOCK_PUBKEY,
    SystemProgram,
    Transaction,
    // TransactionInstruction,
    // sendAndConfirmTransaction,
    // TransactionSignature,
    // Connection,
    SYSVAR_RENT_PUBKEY
} from '@solana/web3.js';
import {
    TOKEN_PROGRAM_ID,
    // ASSOCIATED_TOKEN_PROGRAM_ID
} from "@solana/spl-token";
import * as anchor from '@project-serum/anchor';
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
// import { WalletContextState } from "@solana/wallet-adapter-react";
import { Buffer } from "buffer";
import { showToast } from "./utils";
import { addFlipHistory } from '../actions';

const IDL = require('./coinflip.json');

const PROGRAM_ID = new PublicKey(
    "AVCf2yKdFBnJP6qs3ew4JhRva5LvF3pqrG1CHr5YHVBD"
);
global.Buffer = Buffer;
// const PYTH_ACCOUNT = new PublicKey("HovQMDrbAgAYPCmHVSrezcSmkMtXSSUsLDFANExrZh2J");

const VAULT_SEED = "VAULT_SEED";
const USER_STATE_SEED = "USER-STATE-SEED";
const GLOBAL_STATE_SEED = "GLOBAL-STATE-SEED";

// const TX_SUCCESS = 0;
// const TX_REJECTED = -1;
const TX_FAILED = -10;

export const getVaultKey = async () => {
    const [vaultKey] = await PublicKey.findProgramAddress(
        [Buffer.from(VAULT_SEED)],
        PROGRAM_ID
    );
    return vaultKey;
};

export const getUserStateKey = async (userPk) => {
    const [userStateKey] = await PublicKey.findProgramAddressSync(
        [
            Buffer.from(USER_STATE_SEED),
            userPk.toBuffer(),
            // new Uint8Array([PROGRAM_ID]),
        ],
        PROGRAM_ID
    );
    return userStateKey;
};

export const getGlobalStateKey = async (adminPk) => {
    const [pk] = await PublicKey.findProgramAddress(
        [
            Buffer.from(GLOBAL_STATE_SEED),
            adminPk.toBuffer(),
        ],
        PROGRAM_ID
    );
    return pk;
};

export const getProgram = (wallet, connection) => {
    let provider = new anchor.AnchorProvider(
        connection,
        wallet,
        anchor.AnchorProvider.defaultOptions()
    );
    const program = new anchor.Program(IDL, PROGRAM_ID, provider);
    return program;
};

export const isInitialized = async (wallet, connection) => {
    let program = getProgram(wallet, connection);
    let res = await program.account.globalState.all([]);
    if (res.length > 0) {
        return true;
    }
    return false;
}

export const initialize = async (wallet, connection) => {
    try {
        if (wallet.publicKey === null) throw new WalletNotConnectedError();
        let program = getProgram(wallet, connection);

        const tx = new Transaction().add(
            await program.methods
                .initialize()
                .accounts({
                    admin: wallet.publicKey,
                    globalState: await getGlobalStateKey(wallet.publicKey),
                    systemProgram: SystemProgram.programId,
                    rent: SYSVAR_RENT_PUBKEY
                })
                .instruction()
        );

        return await send(connection, wallet, tx);
    } catch (err) {
        showToast('Initialize Error', 2000, 1);
        // console.log('initialize:', err);
    }
}

export const getGlobalData = async (wallet, connection) => {
    if (!wallet.publicKey) {
        return null;
    }

    let program = getProgram(wallet, connection);
    try {
        let res = await program.account.globalState.all([]);
        if (res.length === 0) {
            return null;
        }
        return res[0].account;
    } catch (error) {
        showToast('get Global Data Error', 2000, 1);
        // console.log('getGlobalData', error);
    }

    return null;
}

export const setGlobalData = async (wallet, connection, devWallet, devFee, winPercentage) => {
    let program = getProgram(wallet, connection);
    try {
        const tx = new Transaction().add(
            await program.methods
                .setInfo(new PublicKey(devWallet), new anchor.BN(devFee), winPercentage)
                .accounts({
                    admin: wallet.publicKey,
                    globalState: await getGlobalStateKey(wallet.publicKey),
                })
                .instruction()
        );
        let txId = await send(connection, wallet, tx);
        if (txId === TX_FAILED) showToast("Setting Save Failed!", 2000, 1);
        else if (txId)
            showToast("Setting Save Success!", 2000, 0);

    }
    catch (err) {
        showToast('Setting Save Error', 2000, 1);
        // console.log('setGlobalData:', err);
    }
}

export const getUserPendingRewards = async (wallet, connection) => {
    if (!wallet.publicKey) {
        return 0;
    }

    let program = getProgram(wallet, connection);
    try {
        let userStateKey = await getUserStateKey(wallet.publicKey);
        let userData = await program.account.userState.fetchNullable(userStateKey);
        if (userData && userData.rewardAmount)
            return userData.rewardAmount / web3.LAMPORTS_PER_SOL;
    } catch (error) {
        showToast('Get User Data Error', 2000, 1);
        // console.log('getUserData', error);
    }

    return 0;
}

export const coinFlip = async (wallet, connection, amount) => {
    let program = getProgram(wallet, connection);
    let globalData = await getGlobalData(wallet, connection);
    if (!globalData) {
        showToast("Not initialized", 2000, 1);
        return false;
    }
    try {
        const vaultKey = await getVaultKey();
        let userStateKey = await getUserStateKey(wallet.publicKey);
        const balance = await connection.getBalance(wallet.publicKey);
        if (balance / web3.LAMPORTS_PER_SOL < amount) {
            showToast('Balane of your wallet in not enough.', 2000, 3);
            return {};
        }

        const tx = new Transaction().add(
            await program.methods
                .coinflip(new anchor.BN(web3.LAMPORTS_PER_SOL * amount))
                .accounts({
                    user: wallet.publicKey,
                    // pythAccount: PYTH_ACCOUNT,
                    globalState: await getGlobalStateKey(globalData.admin),
                    vault: vaultKey,
                    devAccount: globalData.devWallet,
                    userState: userStateKey,
                }).instruction()
        );
        let txHash = await send(connection, wallet, tx);
        let userData = await program.account.userState.fetch(userStateKey);
        addFlipHistory({
            address: wallet.publicKey,
            amount: amount,
            result: userData.lastSpinresult
        });
        return { txHash: txHash, flipResult: userData.lastSpinresult };
    } catch (error) {
        // console.log(error)
        showToast('Coin Flip Error', 2000, 1);
        return null;
    }
}

export const claimRewards = async (wallet, connection) => {
    let program = getProgram(wallet, connection);
    let globalData = await getGlobalData(wallet, connection);;
    if (!globalData) {
        showToast("Not initialized", 2000, 1);
        return 0;
    }
    let provider = new anchor.AnchorProvider(
        connection,
        wallet,
        anchor.AnchorProvider.defaultOptions()
    );
    let balance = 0;
    try {
        const vaultKey = await getVaultKey();
        balance = await provider.connection.getBalance(vaultKey);

        balance = balance / 1000000000;
    }
    catch (err) {
        showToast('Claim Error! Please retry later!', 2000, 1);
        // console.log('getProgramBalance==', err);
    }

    const vaultKey = await getVaultKey();
    let userStateKey = await getUserStateKey(wallet.publicKey);
    let userData = await program.account.userState.fetch(userStateKey);

    let rewards = Number(userData.rewardAmount);
    if (balance < rewards / 1000000000) {
        showToast('A problem has occured in the server. Please retry later!', 2000, 3);
        return;
    }
    if (rewards > 0) {
        try {
            const tx = new Transaction().add(
                await program.methods.claimReward().accounts({
                    user: wallet.publicKey,
                    globalState: await getGlobalStateKey(globalData.admin),
                    vault: vaultKey,
                    userState: userStateKey,
                }).instruction()
            );
            await send(connection, wallet, tx);
            return rewards;
        } catch (error) {
            showToast("Claim Error", 2000, 1);
            // console.log('claim:', error);
            return -1;
        }
    }
}

async function send(
    connection,
    wallet,
    transaction
) {
    try {
        const txHash = await sendTransaction(connection, wallet, transaction);
        let res = await connection.confirmTransaction(txHash);
        if (res.value.err) return TX_FAILED;
        else return txHash;
    }
    catch (err) {
        // console.log(err)
    }
}


export async function sendTransaction(
    connection,
    wallet,
    transaction
) {
    if (wallet.publicKey === null || wallet.signTransaction === undefined)
        return null;
    try {
        transaction.recentBlockhash = (
            await connection.getLatestBlockhash()
        ).blockhash;
        transaction.feePayer = wallet.publicKey;
        const signedTransaction = await wallet.signTransaction(transaction);
        const rawTransaction = await signedTransaction.serialize();

        const txId = await connection.sendRawTransaction(
            rawTransaction,
            {
                skipPreflight: true,
                preflightCommitment: "processed",
            }
        );

        return txId;
    } catch (error) {
        // console.log("tx error= ", error);
        if (error.message.includes("User rejected the request")) {
            showToast("User rejected the request.", 2000, 3);
        } else {
            showToast("An unexpected error occurred. Please try again later.", 2000, 1);
        }
    }
}

export const getProgramBalance = async (wallet, connection) => {
    let provider = new anchor.AnchorProvider(
        connection,
        wallet,
        anchor.AnchorProvider.defaultOptions()
    );

    try {
        const vaultKey = await getVaultKey();
        let balance = await provider.connection.getBalance(vaultKey);

        return balance / 1000000000;
    }
    catch (err) {
        // console.log('getProgramBalance==', err);
    }
};

export const deposit = async (wallet, connection, amount) => {
    try {
        const balance = await connection.getBalance(wallet.publicKey);
        if (balance / web3.LAMPORTS_PER_SOL < amount) {
            showToast('Balane of your wallet in not enough.', 2000, 3);
            return {};
        }

        const vaultKey = await getVaultKey();
        const tx = new Transaction().add(
            SystemProgram.transfer({
                fromPubkey: wallet.publicKey,
                toPubkey: vaultKey,
                lamports: amount * 1000000000
            })
        );
        let txId = await send(connection, wallet, tx);
        if (txId === TX_FAILED) showToast("Deposit Failed!", 2000, 1);
        else if (txId)
            showToast("Deposit Success!", 2000, 0);
    }
    catch (error) {
        showToast("Deposit Failed!", 2000, 1);
    }
};

export const withdraw = async (wallet, connection, amount) => {
    let program = getProgram(wallet, connection);

    const vaultKey = await getVaultKey();
    const globalStateKey = await getGlobalStateKey(wallet.publicKey);
    try {
        const tx = new Transaction().add(
            await program.methods.withdrawAll(new anchor.BN(amount * 1000000000)).accounts({
                admin: wallet.publicKey,
                globalState: globalStateKey,
                vault: vaultKey,
                tokenProgram: TOKEN_PROGRAM_ID,
                systemProgram: SystemProgram.programId,
            }).instruction()
        );
        let txId = await send(connection, wallet, tx);
        // console.log('withdraw=======', txId)
        if (txId === TX_FAILED) showToast("Withdraw Failed!", 2000, 1);
        else if (txId)
            showToast("Withdraw Success!", 2000, 0);
    }
    catch (err) {
        // console.log('withdraw err:', err)
        showToast("Withdraw Failed!", 2000, 1);
    }
};