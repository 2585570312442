import { useState } from "react";
import Layout from "../components/Layout";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import AfterConnected from "../components/AfterConnected";

const Home = () => {
  const wallet = useWallet();
  const { connection } = useConnection();
  const [connected, setConnected] = useState(false);

  return (
    // <Layout setConnected={setConnected}>
    <div className="">
      {wallet.connected ? <AfterConnected /> : <BeforeConnected />}
    </div>
    // </Layout>
  );
};


export default Home;

const BeforeConnected = () => {

  return (
    <div className="container">
      <div className="flex flex-col items-center justify-start">
        <h2 className="text-2xl italic font-bold text-center mr-3 md:text-4xl text-[#4b4b14] mb-3">
          #1 MOST TRUSTED PLACE TO FLIP
        </h2>
        <div className="my-3">
          <img src='/turtleCoin.png' alt="" className="w-48" />
        </div>
        <div>
          <WalletMultiButton className="wallet-adapter-button-trigger"></WalletMultiButton>
        </div>
      </div>
    </div>
  );
};
